import axios from 'axios';
import qs from 'querystring';

const AUTH_URL = '/authenticate';
const APP_URL = '/';


export const authenticate = (username, password) => {
	return axios.post(AUTH_URL, qs.stringify({ username, password }), {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}).then(() => window.location.replace(APP_URL));
}

export const isDomain = (...subDomain) => {
	const host = window.location.host;
	const splittedHost = host.split(/[.:]+/);
	console.log('splittedHost', splittedHost);
	return splittedHost.length > 0 && subDomain.includes(splittedHost[0])
}

export const getSubDomain = () => {
	const [subdomain] = window.location.host.split('.');
	// const query = new URLSearchParams(window.location.search)
	// const tenant = query.get('tenant')
	// return tenant
	return (subdomain);
};

export const getAssets = () => {
	const assets = {
		hasAssets: false,
		bgBottom: '',
		bgColor: '#fff',
		cColor: 'rgba(0, 0, 0, 0.54)',
		bgTop: '',
		logo: '',
		isSafe: false,
		customLogo: null,
		customBackground: null
	};
	switch (getSubDomain()) {
		case 'stagingrsblj':
		case 'blj':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/blj/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/blj/idulfitri/Top.webp',
					logo: '/static/img/tenant/blj/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/blj/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/blj/bg_top.webp',
					logo: '/static/img/tenant/blj/logo.webp'
				});
			}
			break;

		case 'stagingrspm':
		case 'rspm':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rspm/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rspm/idulfitri/Top.webp',
					logo: '/static/img/tenant/rspm/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rspm/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rspm/bg_top.webp',
					logo: '/static/img/tenant/rspm/logo.webp'
				});
			}
			break;

		case 'stagingrsudl':
		case 'rsudl':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsudl/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rsudl/idulfitri/Top.webp',
					logo: '/static/img/tenant/rsudl/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsudl/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rsudl/bg_top.webp',
					logo: '/static/img/tenant/rsudl/logo.png'
				});
			}
			break;

		case 'stagingrskb':
		case 'rskb':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rskb/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rskb/idulfitri/Top.webp',
					logo: '/static/img/tenant/rskb/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rskb/bg_bottom.webp',
					bgColor: '#1b8dc7',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rskb/bg_top.webp',
					logo: '/static/img/tenant/rskb/logo.png'
				});
			}
			break;

		case 'stagingrscmc':
		case 'rscmc':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rscmc/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rscmc/idulfitri/Top.webp',
					logo: '/static/img/tenant/rscmc/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rscmc/bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rscmc/bg_top.webp',
					logo: '/static/img/tenant/rscmc/logo.png'
				});
			}
			break;

		case 'stagingrspirngadi':
		case 'rspirngadi':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rspirngadi/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rspirngadi/idulfitri/Top.webp',
					logo: '/static/img/tenant/rspirngadi/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rspirngadi/bg_bottom.webp',
					bgTop: '/static/img/tenant/rspirngadi/bg_top.webp',
					logo: '/static/img/tenant/rspirngadi/logo.webp'
				});
			}
			break;

		case 'stagingrstobelo':
		case 'rstobelo':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rstobelo/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					bgTop: '/static/img/tenant/rstobelo/idulfitri/Top.webp',
					logo: '/static/img/tenant/rstobelo/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rstobelo/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rstobelo/bg_top.webp',
					logo: '/static/img/tenant/rstobelo/logo.webp'
				});
			}
			break;

		case 'stagingrsdp':
		case 'rsdp':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsserang/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					bgTop: '/static/img/tenant/rsserang/idulfitri/Top.webp',
					logo: '/static/img/tenant/rsserang/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsserang/bg_bottom.webp',
					cColor: 'rgba(0, 0, 0, 0.54)',
					bgTop: '/static/img/tenant/rsserang/bg_top.webp',
					logo: '/static/img/tenant/rsserang/logo.png'
				});
			}
			break;

		case 'stagingrsudsayang':
		case 'rsudsayang':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsudsayang/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rsudsayang/idulfitri/Top.webp',
					logo: '/static/img/tenant/rsudsayang/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsudsayang/bg_bottom_v2.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsudsayang/bg_top.webp',
					logo: '/static/img/tenant/rsudsayang/new-logo.webp'
				});
			}
			break;

		case 'stagingrsam':
		case 'rsam':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/new-year/bottom.webp',
					bgColor: '#4A446E',
					cColor: 'black',
					bgTop: '/static/img/new-year/top.webp',
					logo: '/static/img/tenant/rsam/logo_white.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsam/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsam/bg_top.webp',
					logo: '/static/img/tenant/rsam/logo.png'
				});
			}
			break;

		case 'stagingrsefram':
		case 'rsefram':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsefram/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rsefram/idulfitri/Top.webp',
					logo: '/static/img/tenant/rsefram/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsefram/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsefram/bg_top.webp',
					logo: '/static/img/tenant/rsefram/logo_mini.webp'
				});
			}
			break;

		case 'stagingrscibabat':
		case 'rscibabat':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rscibabat/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rscibabat/idulfitri/Top.webp',
					logo: '/static/img/tenant/rscibabat/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rscibabat/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rscibabat/bg_top.webp',
					logo: '/static/img/tenant/rscibabat/logo.webp'
				});
			}
			break;

		case 'stagingrsudtangerang':
		case 'rsudtangerang':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rstangerang/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rstangerang/idulfitri/Top.webp',
					logo: '/static/img/tenant/rstangerang/idulfitri/Logo.webp',
				})
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rstangerang/bg_bottom_v2.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rstangerang/bg_top.webp',
					logo: '/static/img/tenant/rstangerang/logo.webp'
				})
			}
			break;

		case 'stagingrsbap':
		case 'rsbap':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsbap/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rsbap/idulfitri/Top.webp',
					logo: '/static/img/tenant/rsbap/idulfitri/Logo.webp',
				})
			}
			else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsbap/bg_bottom_v2.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsbap/bg_top.webp',
					logo: '/static/img/tenant/rsbap/logo.webp'
				})
			}
			break;

		case 'stagingrsannisa':
		case 'rsannisa':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsannisa/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rsannisa/idulfitri/Top.webp',
					logo: '/static/img/tenant/rsannisa/idulfitri/Logo.webp',
				})
			}
			else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsannisa/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsannisa/bg_top.webp',
					logo: '/static/img/tenant/rsannisa/logo.webp'
				})
			}
			break;

		case 'stagingrsudsultanimanuddin':
		case 'rssi':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/pangkalanbun/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/pangkalanbun/idulfitri/Top.webp',
					logo: '/static/img/tenant/pangkalanbun/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/pangkalanbun/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/pangkalanbun/bg_top.webp',
					logo: '/static/img/tenant/pangkalanbun/logo.webp',
					bgColor: '#E0F5FF'
				})
			}
			break;

		case 'stagingrsudselong':
		case 'emrrsudsoedjono':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/selong/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/selong/idulfitri/Top.webp',
					logo: '/static/img/tenant/selong/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/selong/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/selong/bg_top.webp',
					logo: '/static/img/tenant/selong/logo.webp',
					bgColor: '#FCFCE1'
				})
			}
			break;

		case 'stagingrsudparung':
		case 'rsudparung':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/parung/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/parung/idulfitri/Top.webp',
					logo: '/static/img/tenant/parung/idulfitri/Logo.webp',
				});

			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/parung/bg_bottom_bsre.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/parung/bg_top.webp',
					logo: '/static/img/tenant/parung/logo.webp',
					bgColor: '#FCFCE1'
				})
			}
			break;
		case 'stagingrsudanuntaloko':
		case 'rsudanuntaloko':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/anuntaloko/idulfitri/Bottom.png',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/anuntaloko/idulfitri/Top.png',
					logo: '/static/img/tenant/anuntaloko/idulfitri/Logo.png',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/anuntaloko/bottom.png',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/anuntaloko/top.png',
					logo: '/static/img/tenant/anuntaloko/logo.png',
					bgColor: '#fdfce0'
				})
			}
			break;

		case 'stagingrsudmimika':
		case 'rsudmimika':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsmimika/idulfitri/Bottom.webp',
					bgOrder: 'tb',
					bgTop: '/static/img/tenant/rsmimika/idulfitri/Top.webp',
					logo: '/static/img/tenant/rsmimika/idulfitri/Logo.webp',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsmimika/bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsmimika/top.webp',
					logo: '/static/img/tenant/rsmimika/logo.webp',
					bgColor: '#c7f5ff'
				})
			}
			break;

		case 'stagingrsudtripat':
		case 'rsudtripat':
			if (hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/tripat/idulfitri/Bottom.png',
					bgOrder: 'tb',
					cColor: '#fff',
					bgTop: '/static/img/tenant/tripat/idulfitri/Top.png',
					logo: '/static/img/tenant/tripat/idulfitri/Logo.png',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/tripat/bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/tripat/top.webp',
					logo: '/static/img/tenant/tripat/logo.webp',
					bgColor: '#acdce9'
				})
			}
			break;

		case 'selong-safemode':
			Object.assign(assets, {
				hasAssets: true,
				bgBottom: '/static/img/tenant/selong/bottom_bw.webp',
				cColor: 'rgba(255, 255, 255, 0.75)',
				bgTop: '/static/img/tenant/selong/top_bw.webp',
				logo: '/static/img/tenant/selong/logo_bw.webp',
				bgColor: '#c7f5ff',
				isSafe: true
			})
			break;

		case 'demo2':
		case 'demo':
			Object.assign(assets, {
				hasAssets: true,
				bgBottom: '/static/img/tenant/demo/bg_bottom.webp',
				bgOrder: 'tb',
				cColor: '#fff',
				bgTop: '/static/img/tenant/demo/bg_top.webp',
				logo: '/static/img/tenant/demo/logo.webp'
			});
			break;
	}
	return (assets);
};

const hasSpecialAssets = () => {
	// Gunakan format ISO 86001 jika ada keperluan jam
	// example : 2024-12-31T16:00:00
    const dateStart = Date.parse('3/26/2025');
	const dateEnd = Date.parse('4/09/2025');

	const now = Date.now();

	return getSubDomain() && now >= dateStart && now < dateEnd;
};